import React from "react"
import { withTheme, useMediaQuery, useTheme } from "@material-ui/core"
import _Image from "../image"
import _Container from "../container"
import localTheme from "./theme"
import styled from "styled-components"
import { useTranslation } from "react-i18next"

export default ({ ...props }) => {
  const theme = useTheme()
  const downXs = useMediaQuery(theme.breakpoints.down("xs"))
  const { t } = useTranslation()

  return (
    <Bg {...props}>
      <Container>
        <h1>Vision</h1>
        <h2>{t("Web3.0を実現する。")}</h2>
        <p>
          {t(
            `"It has been decades since the internet was first introduced to the world. Since then, many great products are born and they changed the world. However, our data is still centrally controlled by a handful of large companies. Every time we use the Internet, we lose control over our data. Technology should be used in a different way. It should be used to empower people rather than exploit one’s privacy and freedom. 

            We, Stake Technologies aim to be the forefront of this movement, to empower the users with their own information, and create a decentralized web that will change the world.`,
            { ns: "company" }
          )}
        </p>
        <Image
          filename="company/vision.png"
          style={{ position: downXs ? "relative" : "absolute" }}
        />
      </Container>
    </Bg>
  )
}

const Bg = styled.div`
  background-color: #f5f5f5;
`

const Container = withTheme(styled(_Container)`
  ${localTheme}
  width: 100%;
  position: relative;
  padding-bottom: 129px;
  // overflow: hidden;
  * {
    z-index: 10;
  }
  & p {
    white-space: pre-wrap;
    max-width: 630px;
  }
  ${props => props.theme.breakpoints.down("xs")} {
    padding-bottom: 0px;
  }
`)

const Image = withTheme(styled(_Image)`
  width: 700px;
  bottom: 0px;
  right: 0px;
  opacity: 0.4;
  ${props => props.theme.breakpoints.down("sm")} {
    opacity: 0.2;
  }
  ${props => props.theme.breakpoints.down("xs")} {
    width: 100vw;
    height: 100vw;
    opacity: 0.4;
  }
`)
